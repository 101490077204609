import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const Menu = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();

  const handleLanguageChange = (newLocale) => {
    const currentPathname = location.pathname;
    const newPathname = currentPathname.replace(`/${i18nLocale}`, `/${newLocale}`);

    window.location.href = newPathname;
  };

  const data = {
    lang: {
      current: {
        url: `/${i18nLocale}`,
        label: i18nLocale.toUpperCase(),
      },
      available: [
        {
          url: "en",
          label: "EN",
        },
        {
          url: "fr",
          label: "FR",
        },
        {
          url: "nl",
          label: "NL",
        },
      ].filter(lang => lang.label !== i18nLocale.toUpperCase()).slice(0, 2),
    },
    navigation: [
      {
        label: intl.formatMessage({ id: "menu.navigation.find_job.label" }),
        items: [
          {
            url: `/${i18nLocale}/jobs`,
            label: intl.formatMessage({ id: "menu.navigation.find_job.items.open_careers" }),
          },
          {
            url: `/${i18nLocale}/why-us-candidate`,
            label: intl.formatMessage({ id: "menu.navigation.find_job.items.why_us_candidate" }),
          },
          {
            url: `/${i18nLocale}/submit-your-cv`,
            label: intl.formatMessage({ id: "menu.navigation.find_job.items.submit_cv" }),
          },
        ],
      },
      {
        // label: intl.formatMessage({ id: "menu.navigation.hire_talent.label" }),
        items: [
          {
            url: `/${i18nLocale}/why-us-company`,
            label: intl.formatMessage({ id: "menu.navigation.hire_talent.items.why_us_company" }),
          },
          {
            url: `/${i18nLocale}/about-us`,
            label: intl.formatMessage({ id: "menu.navigation.hire_talent.items.about_us" }),
          },
          {
            url: `/${i18nLocale}/team`,
            label: intl.formatMessage({ id: "menu.navigation.hire_talent.items.meet_the_team" }),
          },
          {
            url: `/${i18nLocale}/join-our-team`,
            label: intl.formatMessage({ id: "menu.navigation.hire_talent.items.join_our_team" }),
          },
          {
            url: `/${i18nLocale}/referral`,
            label: intl.formatMessage({ id: "menu.navigation.hire_talent.items.referral" }),
          },
          {
            url: `/${i18nLocale}/contact`,
            label: intl.formatMessage({ id: "menu.navigation.hire_talent.items.contact" }),
          },
        ],
      },
    ],
    contacts: [
      {
        city: "Brussels",
        address: "Boulevard du Triomphe 174, 1160 Auderghem",
        email: "brussels@wearesander.com",
        phone: "+32 (0) 2 673 70 77",
      },
      {
        city: "Antwerp",
        address: "Frankrijklei 5, 2000 Antwerpen",
        email: "antwerp@wearesander.com",
        phone: "+32 (0) 3 846 06 26",
      },
      {
        city: "Paris",
        address: "Rue Monceau 58, 75008 Paris",
        email: "paris@wearesander.com",
        phone: "+33 (0) 6 51 01 26 82",
      },
    ],
    cta: {
      label: intl.formatMessage({ id: "menu.cta.label" }),
      email: "contact@wearesander.com",
      socials: {
        // facebook: "https://www.facebook.com/SanderandPartners/",
        linkedin: "https://www.linkedin.com/company/sanderandpartners/",
      },
    },
  };

  return (
    <div
      id="menu"
      className="fixed top-0 left-0 w-screen h-screen backdrop-blur bg-deepBlue laptop:bg-deepBlue/70 text-white z-20 pointer-events-none [&.is-open]:pointer-events-auto transition-opacity duration-300 ease-out-cubic opacity-0 [&.is-open]:opacity-100 overflow-hidden laptop:before:content-[''] before:vw-w before:[--w:840] before:h-screen before:bg-deepBlue before:fixed before:top-0 before:left-0 before:-z-10 after:content-[''] after:w-screen laptop:after:vw-w laptop:after:[--w:840] after:vw-h after:[--h:122] after:bg-deepBlue after:fixed after:top-0 after:left-0 after:z-10"
    >
      <div
        id="menu-container"
        className="gg-container flex items-start overflow-y-scroll overflow-x-hidden h-screen vw-py [--py:122]"
        data-lenis-prevent
      >
        <div id="menu-wrapper" className="w-full min-h-full flex items-center">
          <div
            id="menu-grid"
            className="gg-grid w-full items-center !vw-gap-y [--gap-y:60] mini:[--gap-y:130]"
          >
            {data.navigation && (
              <nav
                id="menu-nav"
                aria-label="Main menu"
                role="navigation"
                className="col-start-1 col-span-12 mini:col-start-7 mini:col-span-12 laptop:col-start-0 desktop:col-start-4 laptop:col-span-8 flex flex-col vw-gap [--gap:41] [&_li]:vw-text [&_li]:[--text:22] [&_li]:vw-leading [&_li]:[--leading:20] [&_li]:vw-tracking [&_li]:[--tracking:1] [&_menu]:flex [&_menu]:flex-col [&_menu]:vw-gap [&_menu]:[--gap:28] [&_small]:block [&_small]:vw-text [&_small]:[--text:16] [&_menu:not(:last-child)]:vw-pb [&_menu:not(:last-child)]:[--pb:41] [&_menu:not(:last-child)]:border-b [&_menu:not(:last-child)]:border-white/40"
              >
                <menu id="menu-mobile-lang" className="mini:!hidden flex !flex-row flex-wrap items-center justify-start !vw-gap ![--gap:8] !border-b-0 !pb-0">
                  <li key="current">
                    <a
                      href={data.lang.current.url}
                      className="inline-flex vw-text [--text:16] vw-leading: [--leading:13] rounded-full vw-px [--px:20] vw-py [--py:13] border bg-lightBlue text-darkGreen border-lightBlue transition-colors duration-150 ease-out-cubic"
                    >
                      {data.lang.current.label}
                    </a>
                  </li>
                  {data.lang.available.map((lang, index) => (
                    <li key={index}>
                      <a
                        onClick={() => handleLanguageChange(lang.url)}
                        className="inline-flex vw-text [--text:16] vw-leading: [--leading:13] rounded-full vw-px [--px:20] vw-py [--py:13] border bg-transparent text-lightBlue border-lightBlue hover:bg-lightBlue hover:text-darkGreen transition-colors duration-150 ease-out-cubic"
                      >
                        {lang.label}
                      </a>
                    </li>
                  ))}
                </menu>
                {data.navigation.map((navItem, index) => (
                  <menu key={index}>
                    {navItem.label && (
                      <div
                        id="menu-label"
                        className="uppercase vw-text [--text:17] leading-[normal] vw-tracking [--tracking:3] text-darkGreen font-semibold"
                      >
                        {navItem.label}
                      </div>
                    )}
                    {navItem.items &&
                      navItem.items.map((item, idx) => (
                        <li
                          key={idx}
                          className="overflow-x-clip overflow-y-visible [--translate-x:-20] hover:[--translate-x:0] [&>a]:[transform:translateX(calc((var(--translate-x)/var(--current-screen-size))*var(--screen-w)))] [&>a]:tranition-[transform] [&>a]:duration-150 [&>a]:ease-out-cubic"
                        >
                          <a
                            href={item.url}
                            className="flex items-center justify-start vw-gap [--gap:12]"
                          >
                            <svg
                              className="vw-w [--w:8] vw-h [--h:13]"
                              viewBox="0 0 8 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.00028 1.49972L6.00055 6.5L1.00028 11.5003"
                                stroke="#FFFFFF"
                                strokeWidth="2"
                                strokeLinecap="round"
                              />
                            </svg>
                            <span>{item.label}</span>
                          </a>
                        </li>
                      ))}
                  </menu>
                ))}
              </nav>
            )}
            {data.cta && (
              <div
                id="menu-cta"
                className="max-laptop:hidden top-1/2 transform -translate-y-1/2 laptop:vw-left laptop:[--left:905] desktop:[--left:990] laptop:fixed laptop:col-start-19 desktop:col-start-17 laptop:col-span-5 flex flex-col vw-gap [--gap:24]"
              >
                {/* {data.cta.label && (
                  <div className="text-black vw-text [--text:25] leading-[126%] font-medium">
                    {data.cta.label}
                  </div>
                )}
                {data.cta.email && (
                  <div>
                    <a
                      href={`mailto:${data.cta.email}`}
                      className="vw-text [--text:18] leading-[200%] transition-colors duration-150 ease-out-cubic text-white hover:underline underline-offset-4"
                    >
                      {data.cta.email}
                    </a>
                  </div>
                )} */}
                {data.contacts && (
                  <div id="menu-contacts" className="flex flex-col vw-gap [--gap:36]">
                    {data.contacts.map((contact, index) => (
                      <div key={index} className="contact-info">
                        <h3 className="uppercase vw-text [--text:17] leading-[normal] vw-tracking [--tracking:3] text-darkGreen font-semibold vw-mb [--mb:6]">{contact.city}</h3>
                        <div className="vw-text [--text:16] leading-[200%]">
                          <a
                            href={`mailto:${contact.email}`}
                            className="transition-colors duration-150 ease-out-cubic text-white hover:text-white hover:underline underline-offset-4"
                          >
                            {contact.email}
                          </a>
                        </div>
                        <div className="vw-text [--text:13] leading-[200%]">
                          <a
                            href={`tel:${contact.phone.replace(/\s/g, "")}`}
                            className="transition-colors duration-150 ease-out-cubic text-white hover:text-white hover:underline underline-offset-4"
                          >
                            {contact.phone}
                          </a>
                        </div>
                        <div className="vw-text [--text:13] leading-[200%]">{contact.address}</div>
                      </div>
                    ))}
                  </div>
                )}
                {data.cta.socials && (
                  <div className="flex items-center justify-start vw-gap [--gap:8]">
                    {/* {data.cta.socials.facebook && (
                      <a
                        href={data.cta.socials.facebook}
                        rel="noreferrer"
                        target="_blank"
                        aria-label="Facebook"
                        title="Facebook"
                        role="button"
                        className="vw-w [--w:42] aspect-square rounded-full border border-white transition-colors duration-150 ease-out-cubic inline-flex items-center justify-center text-white bg-transparent hover:bg-white hover:text-deepBlue"
                      >
                        <svg
                          className="vw-h [--h:14] aspect-[320/512]"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                        >
                          <path
                            fill="currentColor"
                            d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                          />
                        </svg>
                      </a>
                    )} */}
                    {data.cta.socials.linkedin && (
                      <a
                        href={data.cta.socials.linkedin}
                        rel="noreferrer"
                        target="_blank"
                        aria-label="LinkedIn"
                        title="LinkedIn"
                        role="button"
                        className="vw-w [--w:42] aspect-square rounded-full border border-white transition-colors duration-150 ease-out-cubic inline-flex items-center justify-center text-white bg-transparent hover:bg-white hover:text-deepBlue"
                      >
                        <svg
                          className="vw-h [--h:14] aspect-[448/512]"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="currentColor"
                            d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                          />
                        </svg>
                      </a>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
