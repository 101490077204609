import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import Dashboard from "bundles/Internal/components/Dashboard";
import Employees from "bundles/Internal/components/Employees";
import Departments from "bundles/Internal/components/Departments";
import Jobs from "bundles/Internal/components/Jobs";
import Navbar from "bundles/Internal/components/Navbar";
import Invitation from "bundles/Internal/components/Invitation";
import JobOfferCreation from "bundles/Internal/components/JobOfferCreation";
import Profile from "bundles/Internal/components/Profile";
import HRResumes from "bundles/Internal/components/HRResumes";

const InternalContainer = () => {
  return (
    <BrowserRouter>
      <Box
        id="main-container-box-id"
        display="flex"
        flexDirection="column"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <Navbar />
        <Box
          style={{ height: "calc(100% - 64px)", flexGrow: 10, overflow: "scroll" }}
        >
          <Routes>
            <Route
              path={"/internal/invitation"}
              element={<Invitation />}
            />
            <Route
              path={"/internal/employees"}
              element={<Employees />}
            />
            <Route
              path={"/internal/departments"}
              element={<Departments />}
            />
            <Route
              path={"/internal/jobs"}
              element={<Jobs />}
            />
            <Route
              path={"/internal/profile"}
              element={<Profile />}
            />
            <Route
              path={"/internal/hr_resumes"}
              element={<HRResumes />}
            />
            <Route
              path={"/internal/edit_ad/:id"}
              element={<JobOfferCreation />}
            />
            <Route
              path={"/internal/new_ad"}
              element={<JobOfferCreation />}
            />
            <Route
              path={"/internal"}
              element={<Dashboard />}
            />
          </Routes>
        </Box>
      </Box>
    </BrowserRouter>
  );
};

export default InternalContainer;
