import React, { useState, useEffect } from "react";
import { Box, Button, Grid, MenuItem, TextField, Typography, Slider, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { jobStatuses, jobRegions, offerLanguages, languages } from "bundles/common/utils/constants";
import { capitalize } from "bundles/common/utils/helpers";
import sh from "bundles/common/utils/sh";
import CardSelect from "bundles/Internal/components/CardSelect";
import TextEditor from "bundles/Internal/components/TextEditor";


const Informations = ({ values, setValues, handleNextStep }) => {
  const [errors, setErrors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [charCount, setCharCount] = useState(0);
  const [jobTitleCharCount, setJobTitleCharCount] = useState(0);

  const MAX_CHAR_LIMIT_SUMMARY = 160;
  const MAX_CHAR_LIMIT_JOB_TITLE = 62;

  const fetchAllCategories = () => {
    sh.get("internal/all_categories").then((res) => {
      setCategories(res?.data?.categories || []);
    });
  };

  const handleSalaryTypeChange = (event) => {
    const newSalaryType = event.target.value;
    setValues({
      ...values,
      salary_type: newSalaryType,
      min_salary: newSalaryType === "no-salary" ? null : 0,
      max_salary: newSalaryType === "no-salary" ? null : 0,
    });
  };

  const handleSliderChange = (event, newValue) => {
    setValues({
      ...values,
      min_salary: newValue[0],
      max_salary: newValue[1],
    });
    validate({ min_salary: newValue[0], max_salary: newValue[1] });
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    let newValues = { ...values, [name]: value };
    setValues(newValues);
    validate({ [name]: value });

    if (name === "job_title") {
      setJobTitleCharCount(value.length);
    }
  };

  const handleCardSelected = (name, value, multiple) => {
    if (multiple) {
      if (values[name].includes(value)) {
        setValues({
          ...values,
          [name]: values[name].filter((v) => v != value),
        });
      } else {
        setValues({ ...values, [name]: (values[name] || []).concat([value]) });
        validate({ [name]: (values[name] || []).concat([value]) });
      }
    } else {
      setValues({ ...values, [name]: value });
      validate({ [name]: value });
    }
  };

  const handleTextEditor = (fieldName, value) => {
    setValues({ ...values, [fieldName]: value });
    validate({ [fieldName]: value });
  };

  const strip = (htmlString) => {
    if (!htmlString) return "";
    const tmp = document.createElement("DIV");
    tmp.innerHTML = htmlString;
    return tmp.textContent || tmp.innerText || "";
  };

  const validate = (fieldValues = {}) => {
    let temp = { ...errors };
    let required = "Required";

    if ("offer_language" in fieldValues) {
      temp.offer_language = fieldValues.offer_language ? "" : required;
    }

    if ("category_id" in fieldValues) {
      temp.category_id = fieldValues.category_id ? "" : required;
    }

    if ("job_title" in fieldValues) {
      temp.job_title = fieldValues.job_title ? "" : required;
      if (fieldValues.job_title && fieldValues.job_title.length > MAX_CHAR_LIMIT_JOB_TITLE) {
        temp.job_title = `Limit exceeded by ${fieldValues.job_title.length - MAX_CHAR_LIMIT_JOB_TITLE} character(s)`;
      }
    }

    if ("job_status" in fieldValues) {
      temp.job_status = fieldValues.job_status ? "" : required;
    }

    if ("region" in fieldValues) {
      temp.region = fieldValues.region ? "" : required;
    }

    if ("city" in fieldValues) {
      temp.city = fieldValues.city ? "" : required;
    }

    if ("languages" in fieldValues) {
      temp.languages = fieldValues.languages?.length > 0 ? "" : required;
    }

    if (("min_salary" in fieldValues) || ("max_salary" in fieldValues)) {
      if (fieldValues.min_salary === 0 && fieldValues.max_salary === 0) {
        temp.salary = "Please enter a value or select 'No salary'";
      } else {
        temp.salary = "";
      }
    }

    if ("summary" in fieldValues) {
      let summaryStripped = strip(fieldValues.summary);
      if (!summaryStripped) {
        temp.summary = required;
      } else if (summaryStripped.length > MAX_CHAR_LIMIT_SUMMARY) {
        temp.summary = `Limit exceeded by ${summaryStripped.length - MAX_CHAR_LIMIT_SUMMARY} character(s)`;
      } else {
        temp.summary = "";
      }
    }

    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  const validateAndGoNext = (e) => {
    e.preventDefault();
    if (validate(values)) {
      handleNextStep();
    }
  };

  useEffect(() => {
    const strippedValue = strip(values.summary);
    setCharCount(strippedValue.length);
  }, [values.summary]);

  useEffect(() => {
    if (values.job_title) {
      setJobTitleCharCount(values.job_title.length);
    }
  }, [values.job_title]);

  useEffect(() => {
    fetchAllCategories();
  }, []);

  return (
    <Box>
      <h2>Informations</h2>
      <Grid container spacing={2} component="form" onSubmit={validateAndGoNext}>
        <Grid item xs={12}>
          <Box style={{ display: "flex", rowGap: "8px", alignItems: "start", flexDirection: "column" }}>
            <Box style={{ minWidth: "100px"}}>
              <h3>In what language will you write your ad?</h3>
            </Box>
            <Box style={{ flexGrow: 1, maxWidth: "600px"}}>
              <CardSelect
                fieldName="offer_language"
                selected={values.offer_language}
                values={offerLanguages.map((language) => {
                  return {
                    code: language.code,
                    label: language.value,
                  };
                })}
                onChange={handleCardSelected}
              />
              {errors["offer_language"] && (
                <p
                  className="MuiFormHelperText-root MuiFormHelperText-sizeSmall Mui-error"
                  style={{ color: "#f44336" }}
                >
                  {errors["offer_language"]}
                </p>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ display: "flex", rowGap: "8px", alignItems: "start", flexDirection: "column" }}>
            <Box style={{ minWidth: "100px"}}>
              <h3>What is the category of the job?</h3>
            </Box>
            <Box style={{ flexGrow: 1 }}>
              <CardSelect
                fieldName="category_id"
                selected={values.category_id}
                values={categories.map((category) => {
                  return {
                    code: category.id,
                    label: category.name,
                  };
                })}
                onChange={handleCardSelected}
              />
              {errors["category_id"] && (
                <p
                  className="MuiFormHelperText-root MuiFormHelperText-sizeSmall Mui-error"
                  style={{ color: "#f44336" }}
                >
                  {errors["category_id"]}
                </p>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ display: "flex", alignItems: "start", flexDirection: "column" }}>
            <Box style={{ minWidth: "100px"}}>
              <h3>What is the job title?</h3>
            </Box>
            <Box style={{ width: "400px" }}>
              <TextField
                style={{ width: "100%" }}
                size="small"
                value={values.job_title}
                name="job_title"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                {...(errors["job_title"] && {
                  error: true,
                })}
              />
              {jobTitleCharCount > MAX_CHAR_LIMIT_JOB_TITLE ? (
                <Typography style={{ color: "red", fontSize: "14px" }}>
                  {`Limit exceeded by ${jobTitleCharCount - MAX_CHAR_LIMIT_JOB_TITLE} character${jobTitleCharCount - MAX_CHAR_LIMIT_JOB_TITLE === 1 ? "" : "s"}`}
                </Typography>
              ) : (
                <Typography style={{ color: "grey", fontSize: "14px" }}>
                  {`${MAX_CHAR_LIMIT_JOB_TITLE - jobTitleCharCount} character${(MAX_CHAR_LIMIT_JOB_TITLE - jobTitleCharCount === 0 || MAX_CHAR_LIMIT_JOB_TITLE - jobTitleCharCount === 1) ? "" : "s"} left`}
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ display: "flex", rowGap: "8px", alignItems: "start", flexDirection: "column" }}>
            <Box style={{ minWidth: "100px"}}>
              <h3>What is the job status?</h3>
            </Box>
            <Box style={{ width: "100%" }}>
              <Box style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                {jobStatuses.map((status) => (
                  <CardSelect
                    key={status}
                    fieldName="job_status"
                    selected={values.job_status}
                    values={[{ code: status, label: capitalize(status) }]}
                    onChange={handleCardSelected}
                  />
                ))}
              </Box>
              {errors["job_status"] && (
                <p
                  className="MuiFormHelperText-root MuiFormHelperText-sizeSmall Mui-error"
                  style={{ color: "#f44336" }}
                >
                  {errors["job_status"]}
                </p>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ display: "flex", rowGap: "8px", alignItems: "start", flexDirection: "column" }}>
            <Box style={{ minWidth: "100px"}}>
              <h3>In which region?</h3>
            </Box>
            <Box style={{ width: "400px" }}>
              <TextField
                select
                style={{ width: "100%" }}
                size="small"
                name="region"
                defaultValue={values.region}
                value={values.region}
                onChange={handleInputValue}
                {...(errors["region"] && {
                  error: true,
                  helperText: errors["region"],
                })}
              >
                {jobRegions.map((region) => {
                  return (
                    <MenuItem value={region} key={`job-d-select-${region}`}>
                      {
                        region.split("_").map((r) => capitalize(r)).join(" ")
                      }
                    </MenuItem>
                  );
                })}
              </TextField>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ display: "flex", rowGap: "8px", alignItems: "start", flexDirection: "column" }}>
            <Box style={{ minWidth: "100px"}}>
              <h3>In which city?</h3>
              <Box style={{ display: "flex" }}>
                <InfoIcon style={{ marginRight: "4px" }}/>
                <span>Write the city in the language of the offer</span>
              </Box>
            </Box>
            <Box style={{ width: "400px" }}>
              <TextField
                style={{ width: "100%" }}
                size="small"
                value={values.city}
                name="city"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                {...(errors["city"] && {
                  error: true,
                  helperText: errors["city"],
                })}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ display: "flex", rowGap: "8px", alignItems: "start", flexDirection: "column" }}>
            <Box style={{ minWidth: "100px"}}>
              <h3>What are the required languages for the job?</h3>
            </Box>
            <Box style={{ flexGrow: 1, maxWidth: "600px"}}>
              <CardSelect
                fieldName="languages"
                selected={values.languages}
                multiple={true}
                values={languages.map((language) => {
                  return {
                    code: language.code,
                    label: language.value,
                  };
                })}
                onChange={handleCardSelected}
              />
              {errors["languages"] && (
                <p
                  className="MuiFormHelperText-root MuiFormHelperText-sizeSmall Mui-error"
                  style={{ color: "#f44336" }}
                >
                  {errors["languages"]}
                </p>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ display: "flex", rowGap: "6px", alignItems: "start", flexDirection: "column" }}>
            <Box style={{ minWidth: "100px" }}>
              <h3>What is the yearly salary/daily rate?</h3>
            </Box>
            <RadioGroup
              value={values.salary_type || "yearly"}
              style={{ display: "flex", flexDirection: "row" }}
              onChange={handleSalaryTypeChange}
            >
              <FormControlLabel value="yearly" control={<Radio />} label="Yearly" />
              <FormControlLabel value="daily" control={<Radio />} label="Daily" />
              <FormControlLabel value="no-salary" style={{ marginLeft: "2rem" }} control={<Radio />} label="No Salary" />
            </RadioGroup>
            {values.salary_type != "no-salary" &&
              <>
                <Box style={{ width: "50%" }}>
                  <Slider
                    value={[values.min_salary || 0, values.max_salary || 0]}
                    onChange={handleSliderChange}
                    valueLabelDisplay="off"
                    min={0}
                    max={values.salary_type === "yearly" ? 200 : 1000}
                    step={values.salary_type === "yearly" ? 1 : 5}
                  />
                </Box>
                <Typography
                  align="center"
                  variant="h6"
                  fontWeight="600"
                  color="#30324D"
                >
                  {values.salary_type === "yearly" ? (
                    `${values.min_salary || 0}K - ${values.max_salary || 0}K €`
                  ) : (
                    `${values.min_salary || 0} - ${values.max_salary || 0}€/day`
                  )}
                </Typography>
                {errors["salary"] && (
                  <p
                    className="MuiFormHelperText-root MuiFormHelperText-sizeSmall Mui-error"
                    style={{ color: "#f44336" }}
                  >
                    {errors["salary"]}
                  </p>
                )}
              </>
            }
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ display: "flex", rowGap: "8px", alignItems: "start", flexDirection: "column" }}>
            <Box style={{ minWidth: "100px" }}>
              <h3>Write a small summary:</h3>
              <Box style={{ display: "flex" }}>
                <InfoIcon style={{ marginRight: "4px" }} />
                <span>This will be displayed on the small preview of your offer, not on the offer page.</span>
              </Box>
            </Box>
            <Box style={{ flexGrow: 1, width: "100%", height: "200px" }}>
              <TextEditor
                value={values.summary}
                error={errors["summary"]}
                handleChange={(v) => handleTextEditor("summary", v)}
                disabled={charCount > MAX_CHAR_LIMIT_SUMMARY}
              />
            </Box>
            {charCount > MAX_CHAR_LIMIT_SUMMARY ? (
              <Typography style={{ color: "red", fontSize: "14px" }}>
                {`Limit exceeded by ${charCount - MAX_CHAR_LIMIT_SUMMARY} character${charCount - MAX_CHAR_LIMIT_SUMMARY === 1 ? "" : "s"}`}
              </Typography>
            ) : (
              <Typography style={{ color: "grey", fontSize: "14px" }}>
                {`${MAX_CHAR_LIMIT_SUMMARY - charCount} character${(MAX_CHAR_LIMIT_SUMMARY - charCount === 0 || MAX_CHAR_LIMIT_SUMMARY - charCount === 1) ? "" : "s"} left`}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <hr style={{  marginTop: "16px", marginBottom: "32px" }} />
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Button variant="squared" color="primaryOutlined" onClick={() => window.location.pathname = "/internal"}>Cancel</Button>
            <Button variant="squared" color="primaryContained" type="submit">Next</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Informations;
