import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Container, Box, Chip, Card, Checkbox, MenuItem, ListItemText, FormControl, InputLabel, Select, TextField, RadioGroup, FormControlLabel, Radio, FormLabel } from "@mui/material";
import DataTable from "react-data-table-component";
import sh from "bundles/common/utils/sh";
import { capitalize } from "bundles/common/utils/helpers";
import { useSnackbar } from "notistack";
import ConfirmModal from "./ConfirmModal";
import { userRoles } from "bundles/common/utils/constants";

const Employees = () => {
  const currentUser = useSelector((state) => state.current_user);
  const authenticityToken = useSelector((state) => state.authenticity_token);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [regionalDepartments, setRegionalDepartments] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const columns = [
    {
      name: "Name",
      selector: row => `${row.first_name} ${row.last_name}`,
      sortable: true,
      grow: 1,
    },
    {
      name: "Email",
      selector: row => row.email,
      grow: 3,
    },
    {
      name: "Regional Departments",
      selector: (row) => row.regional_departments.map((department) => department.name).join(", "),
    },
    {
      name: "Role",
      selector: row => capitalize(row.role),
    },
    {
      name: "",
      selector: row => {
        if (row.pending_invitation) {
          return (<Chip label="Resend invitation" disabled={loading} color="warning" onClick={() => resendInvitation(row.id)} />);
        }
        else {
          return "";
        }
      },
      width: "160px"
    },
  ];

  const resendInvitation = (candidateId) => {
    setLoading(true);
    sh.post("/internal/resend_invitation", {
      authenticity_token: authenticityToken,
      user: { id: candidateId },
    })
      .then(() => {
        setLoading(false);
        enqueueSnackbar("Invitation sent again!", {
          variant: "success",
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const navigateToInvitation = () => {
    navigate("/internal/invitation");
  };

  const redirectUnauthorizedUsed = () => {
    navigate("/internal");
  };

  const fetchAllUsers = () => {
    sh.get("internal/users").then((res) => {
      setUsers(res?.data?.users || []);
    });
  };

  const fetchAllRegionalDepartments = () => {
    sh.get("internal/all_regional_departments").then((res) => {
      setRegionalDepartments(res?.data?.regional_departments || []);
    });
  };

  const deleteUser = () => {
    setIsConfirmModalOpen(true);
  };

  const handleDeleteConfirmed = () => {
    setLoading(true);
    sh.delete("internal/users", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        authenticity_token: authenticityToken,
        user: { id: currentRow?.id }
      },
    }).then(() => {
      fetchAllUsers();
      enqueueSnackbar("User succesfully deleted!", {
        variant: "success",
      });
    }).catch(() => {
      enqueueSnackbar(
        "Oops, an error occured.",
        { variant: "error" }
      );
    }).finally(() => {
      setLoading(false);
    });
  };

  const ExpandedComponent = ({ data }) => {
    const user = data;
    const [userRole, setUserRole] = useState(user.role);

    const [userRegionalDepartments, setUserRegionalDepartments] = useState(
      user.regional_departments || []
    );

    const [isDirty, setIsDirty] = useState(user.role !== userRole || !arraysEqual(userRegionalDepartments, user.regional_departments));

    function arraysEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) return false;
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
      }
      return true;
    }

    const handleRegionalDepartmentsChange = (latestSelectedId) => {
      const isAlreadySelected = userRegionalDepartments.some(department => department.id === latestSelectedId);
      let newSelection;

      if (isAlreadySelected) {
        if (userRegionalDepartments.length > 1) {
          newSelection = userRegionalDepartments.filter(department => department.id !== latestSelectedId);
        } else {
          newSelection = [...userRegionalDepartments];
        }
      } else {
        const newDepartment = regionalDepartments.find(department => department.id === latestSelectedId);
        newSelection = newDepartment ? [...userRegionalDepartments, newDepartment] : [...userRegionalDepartments];
      }

      setUserRegionalDepartments(newSelection);
    };

    const updateUser = (values) => {
      setLoading(true);
      sh.put("internal/users", {
        authenticity_token: authenticityToken,
        user: values
      }).then(() => {
        fetchAllUsers();
        enqueueSnackbar("User succesfully edited!", {
          variant: "success",
        });
      }).catch(() => {
        enqueueSnackbar(
          "Oops, an error occured.",
          { variant: "error" }
        );
      }).finally(() => {
        setLoading(false);
      });
    };

    useEffect(() => {
      if (user.role !== userRole || !arraysEqual(userRegionalDepartments, user.regional_departments)) {
        setIsDirty(true);
      } else {
        setIsDirty(false);
      }
    }, [userRole, userRegionalDepartments, user.regional_departments]);

    return (
      <Box style={{ padding: "16px 32px"}}>
        <Card style={{ padding: "16px 32px" }}>
          <Box style={{ display: "flex", justifyContent: "space-between"}}>
            <Box>
              <TextField
                select
                style={{ width: "200px" }}
                size="small"
                label="Role"
                name="role"
                value={userRole}
                disabled={user?.id == currentUser.id}
                onChange={(e) => setUserRole(e.target.value)}
              >
                {userRoles.map((role) => {
                  return (
                    <MenuItem value={role.code} key={role.code}>
                      {role.label}
                    </MenuItem>
                  );
                })}
              </TextField>
              <TextField
                select
                SelectProps={{
                  multiple: true,
                  renderValue: (selected) => loading ? "" : `${selected.length} selected`
                }}
                style={{ width: "200px", marginLeft: "16px" }}
                size="small"
                label="Regional departments"
                name="regional_department_ids"
                value={userRegionalDepartments}
                disabled={user?.id === currentUser.id}
              >
                {regionalDepartments.map((regionalDepartment) => (
                  <MenuItem
                    key={regionalDepartment.id}
                    value={regionalDepartment}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={userRegionalDepartments.some(department => department.id === regionalDepartment.id)}
                          onClick={() => handleRegionalDepartmentsChange(regionalDepartment.id)}
                        />
                      }
                      label={regionalDepartment.name}
                    />
                  </MenuItem>
                ))}
              </TextField>

              {isDirty && (
                <Button
                  size="small"
                  variant="squared"
                  color="primaryContained"
                  style={{ marginLeft: "16px" }}
                  disabled={loading}
                  onClick={() => updateUser({ id: user.id, role: userRole, regional_department_ids: userRegionalDepartments.map(department => department.id) })}
                >
                  Save
                </Button>
              )}
            </Box>
            <Button
              variant="squared"
              color="dangerContained"
              size="small"
              disabled={user?.id == currentUser.id || loading}
              onClick={() => deleteUser(user?.id)}
            >
              Delete
            </Button>
          </Box>
        </Card>
      </Box>
    );
  };

  useEffect(() => {
    if (!currentUser || !currentUser["admin?"]) {
      redirectUnauthorizedUsed();
    } else {
      fetchAllUsers();
      fetchAllRegionalDepartments();
    }
  }, []);

  return (
    <Container maxWidth="xl" style={{ paddingTop: "32px" }}>
      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", marginBottom: "16px" }}>
        <h1>Employees</h1>
        <Button variant="squared" color="primaryOutlined" onClick={navigateToInvitation}>Invite a new teammate</Button>
      </Box>
      <DataTable
        columns={columns}
        data={users}
        responsive
        striped
        highlightOnHover
        pointerOnHover
        expandableRows
        expandOnRowClicked
        expandableRowsComponent={ExpandedComponent}
        expandableRowExpanded={(row) => (row === currentRow)}
        onRowClicked={(row) => setCurrentRow(row)}
        onRowExpandToggled={(bool, row) => setCurrentRow(row)}
      />
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        handleConfirm={handleDeleteConfirmed}
        label="You're about to delete this user."
      />
    </Container>
  );
};

export default Employees;
