import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { StaticRouter } from "react-router-dom/server";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import translations from "i18n/translations.json";

import Home from "bundles/Marketing/views/Home";
import JoinOurTeam from "bundles/Marketing/views/JoinOurTeam";
import Careers from "bundles/Marketing/views/Careers";
import JobPage from "bundles/Marketing/views/JobPage";
import WhyUsCandidate from "bundles/Marketing/views/WhyUsCandidate";
import WhyUsCompany from "bundles/Marketing/views/WhyUsCompany";
import SubmitYourCV from "bundles/Marketing/views/SubmitYourCV";
import AboutUs from "bundles/Marketing/views/AboutUs";
import Referral from "bundles/Marketing/views/Referral";
import Contact from "bundles/Marketing/views/Contact";
import Team from "bundles/Marketing/views/Team";

const BuildMessage = () => (
  <div
    style={{
      position: "relative",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    }}
  >
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        filter: "blur(yypx)",
        zIndex: -1,
      }}
    />
    Sander website is currently under maintenance.<br />
    Live on Monday the 29th of January.
  </div>
);

const MarketingContainer = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const path = useSelector((state) => state.path);
  const messages = translations[i18nLocale];

  // return <BuildMessage />;

  return (
    <IntlProvider locale={i18nLocale} key={i18nLocale} messages={messages}>
      <StaticRouter location={path}>
        <Routes>
          <Route
            path={`/${i18nLocale}/join-our-team`}
            element={<JoinOurTeam />}
          />
          <Route
            path={`/${i18nLocale}/jobs`}
            element={<Careers />}
          />
          <Route
            path={`/${i18nLocale}/jobs/:job_title/:id`}
            element={<JobPage />}
          />
          <Route
            path={`/${i18nLocale}/careers`}
            element={<Navigate replace to={`/${i18nLocale}/jobs`} />}
          />
          <Route
            path={`/${i18nLocale}/careers/:job_title/:id`}
            element={<Navigate replace to={`/${i18nLocale}/jobs/:job_title/:id`} />}
          />
          <Route
            path={`/${i18nLocale}/why-us-candidate`}
            element={<WhyUsCandidate />}
          />
          <Route
            path={`/${i18nLocale}/why-us-company`}
            element={<WhyUsCompany />}
          />
          <Route
            path={`/${i18nLocale}/submit-your-cv`}
            element={<SubmitYourCV />}
          />
          <Route
            path={`/${i18nLocale}/about-us`}
            element={<AboutUs />}
          />
          <Route
            path={`/${i18nLocale}/referral`}
            element={<Referral />}
          />
          <Route
            path={`/${i18nLocale}/contact`}
            element={<Contact />}
          />
          <Route
            path={`/${i18nLocale}/team`}
            element={<Team />}
          />
          <Route
            path={`/${i18nLocale}/`}
            element={<Home />}
          />
        </Routes>
      </StaticRouter>
    </IntlProvider>
  );
};

export default MarketingContainer;
