import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import sh from "bundles/common/utils/sh";

import Menu from "../components/globals/Menu";
import Header from "../components/globals/Header";
import Footer from "../components/globals/Footer";

import CareerDetails from "../components/partials/CareerDetails";
import RelatedJobs from "../components/partials/RelatedJobs";
import SideForm from "../components/partials/SideForm";

import { belgianRegions, frenchRegions } from "bundles/common/utils/constants";
import { stripHtml, formatEmploymentType, capitalize } from "bundles/common/utils/helpers";

const JobPage = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const params = useParams();
  const intl = useIntl();

  const [jobOffer, setJobOffer] = useState(null);
  const [relatedJobOffers, setRelatedJobOffers] = useState([]);

  const fetchJobOffer = () => {
    sh.get(`/job_offers/${params.id}`, {
      authenticity_token: authenticityToken,
    }).then((res) => {
      setJobOffer(res?.data);
    });
  };

  const fetchRelatedJobs = () => {
    sh.get(`related_job_offers/${params.id}`, {
      authenticity_token: authenticityToken,
    }).then((res) => {
      setRelatedJobOffers(res?.data);
    }).catch((error) => {
      console.error("Error fetching related job offers:", error);
    });
  };

  useEffect(() => {
    if (jobOffer && jobOffer.offer_status == "online") {
      const addressCountry = belgianRegions.includes(jobOffer.region) ? "Belgium" : frenchRegions.includes(jobOffer.regions) ? "France" : "Unknown";
      const salaryValue = jobOffer.salary_type === "yearly" ? jobOffer.max_salary * 1000 : jobOffer.max_salary;
      const salaryUnitText = jobOffer.salary_type === "yearly" ? "YEAR" : "DAY";

      const createdAtDate = new Date(jobOffer.created_at);
      const validThroughDate = new Date(createdAtDate.setMonth(createdAtDate.getMonth() + 3));
      const validThroughISO = validThroughDate.toISOString();

      const script = document.createElement("script");
      script.type = "application/ld+json";
      script.innerHTML = JSON.stringify({
        "@context": "http://schema.org",
        "@type": "JobPosting",
        "title": jobOffer.job_title,
        "description": stripHtml(jobOffer.description),
        "identifier": {
          "@type": "PropertyValue",
          "name": "Sander",
          "value": jobOffer.id.toString()
        },
        "datePosted": jobOffer.created_at,
        "validThrough": validThroughISO,
        "employmentType": formatEmploymentType(jobOffer.job_status),
        "directApply": true,
        "hiringOrganization": {
          "@type": "Organization",
          "name": "Sander",
          "sameAs": window.location.origin,
          "logo": "https://wearesander.com/static/images/sander-logo.jpg"
        },
        "jobLocation": {
          "@type": "Place",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": jobOffer.city,
            "addressRegion": jobOffer.region.split("_").map((r) => capitalize(r)).join(" "),
            "addressCountry": addressCountry
          }
        },
        "baseSalary": {
          "@type": "MonetaryAmount",
          "currency": "EUR",
          "value": {
            "@type": "QuantitativeValue",
            "value": salaryValue,
            "unitText": salaryUnitText
          }
        }
      });
      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    }
  }, [jobOffer]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("applied") === "true") {
      window.onload = () => {
        setTimeout(() => {
          const scrollToElement = document.getElementById("scroll-to-after-application");
          if (scrollToElement) {
            const topPos = scrollToElement.offsetTop;
            window.scrollTo({
              top: topPos,
              behavior: "smooth"
            });
          }
        }, 200);
      };
    }
  }, []);

  useEffect(() => {
    fetchJobOffer();
    fetchRelatedJobs();
  }, []);

  const relatedJobsData = {
    additionalClasses: "bg-lightBlue/[.35] text-darkGreen",
    title: intl.formatMessage({ id: "career_details.open_vacancies" }),
    cards: relatedJobOffers,
    button_label: "Apply",
    managed_by_label: "Managed by"
  };

  return (
    <div id="job-page">
      <Menu />
      <Header />
      <div id="top" className="block bg-deepBlue vw-h [--h:85] tablet:[--h:106] desktop:[--h:122]"></div>

      <main>
        <CareerDetails jobOffer={jobOffer} />
        <div id="scroll-to-after-application"></div>
        <RelatedJobs data={relatedJobsData} />
        <SideForm jobOffer={jobOffer} />
      </main>

      <Footer />
    </div>
  );
};

export default JobPage;
